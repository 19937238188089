import * as React from "react";

import Layout from "../components/layout/layout";
import SEO from "../components/seo";
import Container from "../components/ui-library/container/container";
import pageStyles from "../components/ui-library/design-tokens/common-page-content";
import { grid } from "../components/ui-library/design-tokens/grid";
import Heading from "../components/ui-library/text/heading";

const PrivacyPolicyPage: React.FC = () => {
  return (
    <Layout>
      <SEO
        title="Privacy policy"
        meta={[{ name: "robots", content: "noindex" }]}
      />

      <Container css={pageStyles}>
        <Heading
          component="h1"
          size="large"
          margin={{ desktop: { bottom: grid(4) }, mobile: { bottom: grid(3) } }}
        >
          Privacy policy
        </Heading>

        <p>
          <strong>Updated October, 2020</strong>
        </p>
        <p>
          This following document sets forth the Privacy Policy for the
          COINOMICS, https://coinomics.pro
        </p>
        <p>
          COINOMICS is committed to providing you with the best possible
          customer service experience. COINOMICS is bound by the Privacy Act
          1988 (Cth), which sets out a number of principles concerning the
          privacy of individuals.
        </p>

        <Heading component="h2" margin={{ top: grid(4), bottom: grid(2) }}>
          Collection of your personal information
        </Heading>
        <p>
          There are many aspects of the site which can be viewed without
          providing personal information, however, for access to future
          COINOMICS customer support features you are required to submit
          personally identifiable information. This may include but not limited
          to a unique username and password, or provide sensitive information in
          the recovery of your lost password.
        </p>

        <Heading component="h2" margin={{ top: grid(4), bottom: grid(2) }}>
          Use of your personal information
        </Heading>
        <p>
          For each visitor to reach the site, we expressively collect the
          following non-personally identifiable information, including but not
          limited to browser type, version and language, operating system, pages
          viewed while browsing the Site, page access times and referring
          website address. This collected information is used solely internally
          for the purpose of gauging visitor traffic, trends and delivering
          personalized content to you while you are at this Site.
        </p>
        <p>
          From time to time, we may use customer information for new,
          unanticipated uses not previously disclosed in our privacy notice. If
          our information practices change at some time in the future we will
          use for these new purposes only, data collected from the time of the
          policy change forward will adhere to our updated practices.
        </p>

        <Heading component="h2" margin={{ top: grid(4), bottom: grid(2) }}>
          Changes to this privacy policy
        </Heading>
        <p>
          COINOMICS reserves the right to make amendments to this Privacy Policy
          at any time. If you have objections to the Privacy Policy, you should
          not access or use the Site.
        </p>

        <Heading component="h2" margin={{ top: grid(4), bottom: grid(2) }}>
          Accessing your personal information
        </Heading>
        <p>
          You have a right to access your personal information, subject to
          exceptions allowed by law. If you would like to do so, please let us
          know. You may be required to put your request in writing for security
          reasons. COINOMICS reserves the right to charge a fee for searching
          for, and providing access to, your information on a per request basis.
        </p>

        <Heading component="h2" margin={{ top: grid(4), bottom: grid(2) }}>
          Contacting us
        </Heading>
        <p>
          COINOMICS welcomes your comments regarding this Privacy Policy. If you
          have any questions about this Privacy Policy and would like further
          information, please contact us.
        </p>
        <p>Email: info@coinomics.pro</p>
      </Container>
    </Layout>
  );
};

export default PrivacyPolicyPage;
